import { create } from "zustand";
import useApi from "../helper/apiConfig";
import { apiRoutes } from "../helper/constant";

// Zustand store definition
export const useREOutcomesStore = create((set) => ({
  // get list
  reOutcomesListData: null,
  isREOutcomesListLoading: false,
  isREOutcomesListSuccess: false,
  isREOutcomesListError: false,

  // get users list
  reOutcomesUsersListData: null,
  isREOutcomesUsersListLoading: false,
  isREOutcomesUsersListSuccess: false,
  isREOutcomesUsersListError: false,

  // get single campaign
  reOutcomesData: null,
  isREOutcomesLoading: false,
  isREOutcomesSuccess: false,
  isREOutcomesError: false,

  // post
  postREOutcomesData: null,
  isPostREOutcomesLoading: false,
  isPostREOutcomesSuccess: false,
  isPostREOutcomesError: false,
  postREOutcomesErrorData: null,

  // put
  putREOutcomesData: null,
  isPutREOutcomesLoading: false,
  isPutREOutcomesSuccess: false,
  isPutREOutcomesError: false,
  putREOutcomesErrorData: null,

  // put status
  putREOutcomesStatusData: null,
  isPutREOutcomesStatusLoading: false,
  isPutREOutcomesStatusSuccess: false,
  isPutREOutcomesStatusError: false,

  setState: (newState) => set((state) => ({ ...state, ...newState })),
}));

// Custom hook that uses useApi and Zustand store together
export const useFetchREOutcomesList = () => {
  const reOutcomesApi = useApi(process.env.REACT_APP_BASE_URL);
  const { setState } = useREOutcomesStore();

  const fetchREOutcomesList = async (queryParams = {}) => {
    setState({
      isREOutcomesListLoading: true,
      isREOutcomesListError: false,
      isREOutcomesListSuccess: false,
    });

    try {
      const query = new URLSearchParams(queryParams).toString();
      const response = await reOutcomesApi.get(
        `${apiRoutes.reOutcomes.reOutcomes}/?${query}`,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
            instanceId: sessionStorage.getItem("instanceId"),
          },
        }
      );
      const { data: reOutcomesData } = response;
      setState({
        reOutcomesListData: reOutcomesData,
        isREOutcomesListSuccess: true,
      });
    } catch (error) {
      setState({ isREOutcomesListError: true });
      console.error("Failed to fetch data:", error);
    } finally {
      setState({ isREOutcomesListLoading: false });
    }
  };

  return fetchREOutcomesList;
};

export const useFetchREOutcomesUsersList = () => {
  const reOutcomesApi = useApi(process.env.REACT_APP_BASE_URL);
  const { setState } = useREOutcomesStore();

  const fetchREOutcomesUsersList = async (id, queryParams = {}) => {
    setState({
      isREOutcomesUsersListLoading: true,
      isREOutcomesUsersListError: false,
      isREOutcomesUsersListSuccess: false,
    });

    try {
      const query = new URLSearchParams(queryParams).toString();
      const response = await reOutcomesApi.get(
        `${apiRoutes.reOutcomes.users}/${id}/?${query}`,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
            instanceId: sessionStorage.getItem("instanceId"),
          },
        }
      );
      const { data: reOutcomesData } = response;
      setState({
        reOutcomesUsersListData: reOutcomesData,
        isREOutcomesUsersListSuccess: true,
      });
    } catch (error) {
      setState({ isREOutcomesUsersListError: true });
      console.error("Failed to fetch data:", error);
    } finally {
      setState({ isREOutcomesUsersListLoading: false });
    }
  };

  return fetchREOutcomesUsersList;
};

export const useFetchREOutcomes = () => {
  const reOutcomesApi = useApi(process.env.REACT_APP_BASE_URL);
  const { setState } = useREOutcomesStore();

  const fetchREOutcomes = async (id) => {
    setState({
      isREOutcomesLoading: true,
      isREOutcomesError: false,
      isREOutcomesSuccess: false,
    });

    try {
      const response = await reOutcomesApi.get(
        `${apiRoutes.reOutcomes.reOutcomes}/${id}`,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
            instanceId: sessionStorage.getItem("instanceId"),
          },
        }
      );
      const { data: reOutcomesData } = response;
      setState({
        reOutcomesData: reOutcomesData,
        isREOutcomesSuccess: true,
      });
    } catch (error) {
      setState({ isREOutcomesError: true });
      console.error("Failed to fetch data:", error);
    } finally {
      setState({ isREOutcomesLoading: false });
    }
  };

  return fetchREOutcomes;
};

export const usePostREOutcomes = () => {
  const reOutcomesApi = useApi(process.env.REACT_APP_BASE_URL);
  const { setState } = useREOutcomesStore();

  const postREOutcomes = async (data) => {
    setState({
      isPostREOutcomesLoading: true,
      isPostREOutcomesError: false,
      isPostREOutcomesSuccess: false,
    });

    try {
      const response = await reOutcomesApi.post(
        `${apiRoutes.reOutcomes.reOutcomes}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
            instanceId: sessionStorage.getItem("instanceId"),
          },
        }
      );
      const { data: reOutcomesData } = response;
      setState({
        postREOutcomesData: reOutcomesData,
        isPostREOutcomesSuccess: true,
      });
    } catch (error) {
      setState({
        isPostREOutcomesError: true,
        postREOutcomesErrorData: error.response.data.status.message,
      });
      console.error("Failed to fetch data:", error);
    } finally {
      setState({ isPostREOutcomesLoading: false });
    }
  };

  return postREOutcomes;
};

export const usePutREOutcomes = () => {
  const reOutcomesApi = useApi(process.env.REACT_APP_BASE_URL);
  const { setState } = useREOutcomesStore();

  const putREOutcomes = async (id, data) => {
    setState({
      isPutREOutcomesLoading: true,
      isPutREOutcomesError: false,
      isPutREOutcomesSuccess: false,
    });

    try {
      const response = await reOutcomesApi.put(
        `${apiRoutes.reOutcomes.reOutcomes}/${id}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
            instanceId: sessionStorage.getItem("instanceId"),
          },
        }
      );
      const { data: reOutcomesData } = response;
      setState({
        putREOutcomesData: reOutcomesData,
        isPutREOutcomesSuccess: true,
      });
    } catch (error) {
      setState({
        isPutREOutcomesError: true,
        putREOutcomesErrorData: error.response.data.status.message,
      });
      console.error("Failed to fetch data:", error);
    } finally {
      setState({ isPutREOutcomesLoading: false });
    }
  };

  return putREOutcomes;
};

export const usePutREOutcomesStatus = () => {
  const reOutcomesApi = useApi(process.env.REACT_APP_BASE_URL);
  const { setState } = useREOutcomesStore();

  const putREOutcomesStatus = async (id, data) => {
    setState({
      isPutREOutcomesStatusLoading: true,
      isPutREOutcomesStatusError: false,
      isPutREOutcomesStatusSuccess: false,
    });

    try {
      const response = await reOutcomesApi.put(
        `${apiRoutes.reOutcomes.status}/${id}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
            instanceId: sessionStorage.getItem("instanceId"),
          },
        }
      );
      const { data: reOutcomesData } = response;
      setState({
        putREOutcomesStatusData: reOutcomesData,
        isPutREOutcomesStatusSuccess: true,
      });
    } catch (error) {
      setState({
        isPutREOutcomesStatusError: true,
      });
      console.error("Failed to fetch data:", error);
    } finally {
      setState({ isPutREOutcomesStatusLoading: false });
    }
  };

  return putREOutcomesStatus;
};
